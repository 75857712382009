<template>
    <el-dialog width="70%" :title="!dataForm.id ? '新增配置' : '编辑配置'" :close-on-click-modal="false" :visible.sync="visible">
        <h3>基础信息编辑：<span class="tips">任务生成后不可编辑</span></h3>
        <el-form :model="dataForm" ref="dataForm" label-width="120px" :rules="dataRule">

            <el-form-item label="操作系统：" prop="platformName">
                <el-select v-model="dataForm.platformName" clearable placeholder="请选择" :disabled="dataForm.id !== 0">
                    <el-option v-for="item in osOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="客户端版本：" prop="appVersion">
                <el-input v-model="dataForm.appVersion" placeholder="请输入版本号" class="input-width"
                    :disabled="dataForm.id !== 0"></el-input>
            </el-form-item>

            <el-form-item label="用户语种：" prop="lang">
                <el-select v-model="dataForm.lang" clearable placeholder="请选择" size="small" :disabled="dataForm.id !== 0">
                    <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <h3>推送信息配置：<span class="tips">任务生成后可编辑</span></h3>

            <el-form-item label="版本文案编辑：" prop="versionDescription">
                <el-input type="textarea" placeholder="请输入内容" v-model="dataForm.versionDescription" class="input-width"
                    resize="none" :rows="2">
                </el-input>
            </el-form-item>
            <el-form-item label="应用商城链接：" prop="appStoreUrl">
                <el-input type="textarea" placeholder="请输入内容" v-model="dataForm.appStoreUrl" class="input-width"
                    resize="none" :rows="2">
                </el-input>
            </el-form-item>

            <el-form-item label="是否强更新：" prop="remindUpgrade">
                <el-select v-model="dataForm.remindUpgrade" clearable placeholder="请选择" size="small">
                    <el-option v-for="item in remindUpgradeOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="beforeSubmit()">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>

const sourceDataForm = {
    id: 0,
    platformName: '',
    platformNameList: [],
    appVersion: '',
    lang: '',
    langList: [],
    versionDescription: '',
    appStoreUrl: '',
    remindUpgrade: 0,

}
export default {
    data() {
        return {
            visible: false,
            dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }],
            },
            dataRule: {
                'platformName': [
                    { required: true, message: '请选择操作系统', trigger: 'blur' },
                ],
                'lang': [
                    { required: true, message: '请选择用户语种', trigger: 'blur' }
                ],
                'appVersion': [
                    { required: true, message: '版本信息不能为空', trigger: 'blur' }
                ],
                'versionDescription': [
                    { required: true, message: '版本文案编辑不能为空', trigger: 'blur' }
                ],
                'remindUpgrade': [
                    { required: true, message: '请选择是否强更', trigger: 'blur' }
                ],
            },
            remindUpgradeOpt: [
                {
                    value: 1,
                    label: '是'
                },
                {
                    value: 0,
                    label: '否'
                }
            ],
            osOpt: [
                {
                    value: 'all',
                    label: 'ALL'
                },
                {
                    value: 'ios',
                    label: 'IOS客户端版本'
                },
                {
                    value: 'android',
                    label: 'Android客户端版本'
                },
            ],
            langOpt: [
                {
                    value: 'ar',
                    label: '阿拉伯语'
                },
                {
                    value: 'en',
                    label: '英语'
                },
                {
                    value: 'all',
                    label: 'ALL'
                }
            ],
        }
    },
    methods: {
        init(row) {
            this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
            this.dataForm.id = !row ? 0 : row.id
            if (this.dataForm.id === 0) {
                // 新增模式
                this.visible = true
                return;
            }
            if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()

            this.visible = true
            const { platformName, appVersion, lang, versionDescription, remindUpgrade, appStoreUrl } = row
            this.dataForm.platformName = platformName
            this.dataForm.appVersion = appVersion
            this.dataForm.lang = lang
            this.dataForm.versionDescription = versionDescription
            this.dataForm.remindUpgrade = remindUpgrade
            this.dataForm.appStoreUrl = appStoreUrl

        },

        beforeSubmit() {
            this.$confirm('当前配置信息是否已确认无误?', '提示', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                this.dataFormSubmit();
            })
        },
        // 表单提交
        dataFormSubmit() {

            this.$refs['dataForm'].validate((valid) => {
                if (valid) {

                    if (this.dataForm.lang === 'all') {
                        this.dataForm.langList = this.dataForm.langList.concat(['en', 'ar'])
                    } else {
                        this.dataForm.langList.push(this.dataForm.lang)
                    }
                    if (this.dataForm.platformName === 'all') {
                        this.dataForm.platformNameList = this.dataForm.platformNameList.concat(['ios', 'android'])
                    } else {
                        this.dataForm.platformNameList.push(this.dataForm.platformName)
                    }

                    console.log('dataForm', this.dataForm)
                    this.$http({
                        url: this.$http.adornUrl(`/sys/config/upgradeVersion`),
                        method: 'post',
                        data: this.$http.adornData({
                            ...this.dataForm
                        })
                    }).then(({ data }) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1000,
                                onClose: () => {
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        } else {
                            this.$message.error(data.msg)
                        }
                    })
                }
            })
        }
    }
}


</script>

<style scoped lang="scss">
h3 {
    padding: 2vh 0;
    border-top: 1px solid rgba(184, 184, 184, 0.6)
}

.input-width {
    width: 30vw;
}

.tips {
    color: rgba(236, 128, 141, 1);
}
</style>